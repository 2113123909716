<template>
  <delivery-zone-form v-if="deliveryZoneDetail" :deliveryZone="deliveryZoneDetail" @submit="update" />
</template>
<script>
import { mapState, mapActions } from 'vuex'
import navigationMixin from '@/mixins/navigation'
import DeliveryZoneForm from '@/components/DeliveryZone/DeliveryZoneForm'

export default {
  mixins: [navigationMixin],
  components: {
    DeliveryZoneForm
  },
  computed: {
    ...mapState('delivery', ['deliveryZoneDetail']),
    ...mapState('shared', ['error'])
  },
  methods: {
    ...mapActions('delivery', ['getDeliveryZone', 'updateDeliveryZone']),
    ...mapActions('notification', ['showNotification']),
    async update (deliveryZone) {
      await this.updateDeliveryZone(deliveryZone)
      if (this.error) {
        this.showNotification({ message: this.error.message, color: 'var(--app-popup-bg-color-danger)', y: 'top', x: 'right', buttonColor: '#FFFFFF' })
        return
      }

      this.showNotification({ message: 'Zona de envió actualizada exitosamente.', color: 'var(--app-popup-bg-color-success)', y: 'top', x: 'right', buttonColor: '#FFFFFF' })
      this.goToRouteByName('delivery-zones')
    }
  },
  mounted () {
    this.getDeliveryZone(this.$route.params.id)
  }
}
</script>
